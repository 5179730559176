<template>
  <Page name="Contact">
    <v-row>
      <Banner text="Get in touch"/>
    </v-row>
    <v-row align="stretch" justify="center">
      <v-col class="d-flex flex-column"
             style="flex-direction:column"
             xs="12"
             sm="12"
             md="4">
        <ContactCard class="flex-grow-1"/>
      </v-col>
      <v-col class="d-flex flex-column"
             style="flex-direction:column"
             xs="12"
             sm="12"
             md="4">
        <OpeningHours class="flex-grow-1"/>
      </v-col>
    </v-row>
    <v-row>
      <Banner text="Our Locations"/>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <h2 class="text-center">Louth (HQ)</h2>
        <GoogleMap source='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2344.9966191288772!2d-6.381285783837378!3d54.00283558012186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860cc06fee1b07d%3A0xab652bce2bf37118!2sControlSoft%20Automation%20Systems!5e0!3m2!1sen!2sie!4v1599466382989!5m2!1sen!2sie'/>
      </v-col>
      <v-col>
        <h2 class="text-center">Belfast</h2>
        <GoogleMap source='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d972.2262863117693!2d-5.876004660112712!3d54.580086261472694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4861096ed3815a81%3A0x6b461f76a884847d!2sCastlereagh%20Rd%2C%20Belfast%20BT5%206BQ%2C%20UK!5e0!3m2!1sen!2sie!4v1738942154087!5m2!1sen!2sie'/>
      </v-col>
      <v-col>
        <h2 class="text-center">Limerick</h2>
        <GoogleMap source='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1211.0293234651986!2d-8.65773236074356!3d52.622784293022114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b5b407084a893%3A0x6cba8af0f17328cc!2sRobe%20House%2C%20Cloughkeating%20Ave%2C%20Raheen%20Business%20Park%2C%20Limerick%2C%20V94%20FVF8!5e0!3m2!1sen!2sie!4v1738941703427!5m2!1sen!2sie'/>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from '../templates/Page/Page';
import GoogleMap from '@/components/GoogleMap';
import ContactCard from '@/Pages/contact_content/ContactCard';
import OpeningHours from '@/Pages/contact_content/OpeningHours';
import Banner from '@/components/Banner';

export default {
  name: 'ContactUs',
  metaInfo: {
    title: 'Contact us',
    htmlAtts: {
      lang: 'en',
    },
  },
  components: {
    Banner,
    OpeningHours,
    ContactCard,
    GoogleMap,
    Page,
  },
};
</script>

<style scoped>

</style>

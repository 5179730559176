<template>
  <div class="map-responsive">
    <iframe
        :src="source"
        frameborder="0"
        style="border:0;"
        allowfullscreen="true"
        aria-hidden="false"
        tabindex="0">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    source: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
</style>
